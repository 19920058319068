import React from 'react';
import { useHistory, useRouteMatch, Route, Switch } from 'react-router-dom';

import { useAuthState, useAuthDispatch } from '../../contexts/auth';
import { logoutUser } from '../../contexts/auth/actions';
import { DashboardProvider } from '../../contexts/dashboard';

import Overview from './Overview';
import Users from './Users';
import Organisations from './Organisations';

import Header from '../../components/Header';

function DashboardHome() {
  const history = useHistory();
  const { path } = useRouteMatch();
  const authDispatch = useAuthDispatch();
  const { loading, error, user } = useAuthState();

  const handleUserLogout = () => {
    authDispatch(logoutUser());
    history.push('/login');
  };

  const renderView = () => {
    if (loading) {
      return <span>Loading...</span>;
    }

    if (!loading && error) return <span>Error! {error}</span>;

    return (
      <Switch>
        <Route exact path={path} component={Overview} />
        <Route path={`${path}/users`} component={Users} />
        <Route path={`${path}/organisations`} component={Organisations} />
      </Switch>
    );
  };

  return (
    <DashboardProvider>
      <main className="relative min-h-screen bg-gray-100">
        <Header user={user} logout={handleUserLogout} />
        <div className="min-h-screen pt-16 mt-2">{renderView()}</div>
        <footer className="px-6 py-4">Footer</footer>
      </main>
    </DashboardProvider>
  );
}

export default DashboardHome;

import React from 'react';
import PropTypes from 'prop-types';
import { ChevronDown } from 'react-feather';

type RawValue = string | number;
type OptionProps = {
  key?: string;
  value: string | number;
  label: string;
};

interface ISelectProps {
  loading: boolean;
  label?: string;
  name: string;
  placeholder?: string;
  value?: RawValue;
  selectOptions: Array<OptionProps>;
  error?: string | null;
  onChange?: (event: React.ChangeEvent<HTMLSelectElement>) => void;
  onSelect?: () => void;
  onFocus?: () => void;
  onBlur?: () => void;
}

const defaultProps = {
  loading: false,
  placeholder: 'Select',
  selectOptions: [],
};

function Select(props: ISelectProps) {
  const {
    loading,
    // placeholder,
    // value,
    onChange,
    // onSelect,
    // onFocus,
    // onBlur,
    label,
    name,
    selectOptions,
    error,
  } = props;
  const hasOptions = selectOptions && selectOptions.length > 0;

  const renderOptions = () => {
    if (loading) return <option>loading...</option>;
    if (hasOptions && selectOptions) {
      return (
        <>
          {selectOptions.map((item: OptionProps, i) => {
            return <option key={item.key || i}>{item.label}</option>;
          })}
        </>
      );
    }
    return <option>No Data</option>;
  };

  return (
    <div className="relative w-full mb-8">
      {label && (
        <label
          className="block capitalize text-gray-800 text-sm mb-2"
          htmlFor={name}>
          {label}
        </label>
      )}
      <div className="inline-block relative w-full">
        <select
          onChange={onChange}
          name={name}
          className="appearance-none block w-full bg-gray-100 text-gray-700 text-sm border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary">
          <option value="">--Please choose an option--</option>
          {renderOptions()}
        </select>
        <div className="pointer-events-none absolute inset-y-0 right-0 flex items-center px-4 text-gray-700">
          <span className="fill-current h-4 w-4">
            <ChevronDown size="16" />
          </span>
        </div>
      </div>
      {error && <span className="mt-2 text-xs text-error">{error}</span>}
    </div>
  );
}

Select.propTypes = {
  selectOptions: PropTypes.arrayOf(PropTypes.shape({})),
};
Select.defaultProps = defaultProps;
export default Select;

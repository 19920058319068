import React from 'react';

interface ISpinProps {
  children: React.ReactNode;
  loading: boolean;
}

const defaultProps = {
  loading: false,
};

function Spin(props: ISpinProps) {
  const { children, loading } = props;

  const renderView = () => {
    if (loading) {
      return <span className="block text-center">Loading...</span>;
    }
    return React.createElement('div', {}, children);
  };

  return <div className="h-full w-full">{renderView()}</div>;
}

Spin.defaultProps = defaultProps;
export default Spin;

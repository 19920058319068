import React from 'react';
import { Layers } from 'react-feather';

import { useDashboardState } from '../../contexts/dashboard';

import Table from '../../components/Table/Table';
import TableHeader from '../../components/Table/TableHeader';
import TableBody from '../../components/Table/TableBody';
import TableRow from '../../components/Table/TableRow';
import TableCell from '../../components/Table/TableCell';

function Organisations() {
  const { organisations } = useDashboardState();

  return (
    <>
      <div className="container relative h-full px-6 py-4">
        <div className="flex items-center bg-white px-6 py-4">
          <span className="mr-4 text-gray-500">
            <Layers size="16" />
          </span>
          <h2 className="text-black text-xl">Organisations</h2>
        </div>
        <div className="block w-full overflow-x-auto">
          <Table>
            <TableHeader>
              <TableCell type="th">S/N</TableCell>
              <TableCell type="th">Name</TableCell>
              <TableCell type="th">Email</TableCell>
              <TableCell type="th">Address</TableCell>
              <TableCell type="th">Phone Number</TableCell>
            </TableHeader>
            <TableBody>
              {organisations.list.map((organisation, index) => {
                return (
                  <TableRow key={organisation.id}>
                    <TableCell>{index + 1}</TableCell>
                    <TableCell>{organisation.name}</TableCell>
                    <TableCell>{organisation.email}</TableCell>
                    <TableCell>{organisation.address}</TableCell>
                    <TableCell>{organisation.phoneNumber}</TableCell>
                  </TableRow>
                );
              })}
            </TableBody>
          </Table>
        </div>
      </div>
    </>
  );
}

export default Organisations;

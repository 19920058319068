import React from 'react';

interface ITableHeaderProps {
  children: React.ReactNode;
}

function TableHeader(props: ITableHeaderProps) {
  const { children } = props;

  return (
    <thead>
      <tr>{children}</tr>
    </thead>
  );
}

export default TableHeader;

import { gql } from '@apollo/client';

import { AlertTypes } from '../../components/Alert';

export interface IDashboardAlert {
  type?: typeof AlertTypes[number];
  message: string;
}

export const FETCH_ORAGNISATIONS = gql`
  query Query {
    fetchOrganizations {
      totalCount
      list {
        id
      }
    }
  }
`;

export const CREATE_ORGANISATION = gql`
  mutation Mutation($createOrganizationInput: CreateOrganizationInput!) {
    createOrganization(createOrganizationInput: $createOrganizationInput) {
      id
      name
      address
      email
      phoneNumber
    }
  }
`;

export const CREATE_APP_ADMIN = gql`
  mutation Mutation($createAppAdminInput: CreateAppAdminInput!) {
    createAppAdmin(createAppAdminInput: $createAppAdminInput) {
      id
      email
      role
    }
  }
`;

export const CREATE_ORGANISATION_ADMIN = gql`
  mutation Mutation(
    $createOrganizationAdminInput: CreateOrganizationAdminInput!
  ) {
    createOrganizationAdmin(
      createOrganizationAdminInput: $createOrganizationAdminInput
    ) {
      id
      email
      role
    }
  }
`;

/**
 * @function isEmpty
 * @summary checks if a value is an empty string or array
 * @param {string|[]} value - string value or array
 * @returns boolean value
 */
export const isEmpty = (value: string | []) => {
  if (Array.isArray(value)) {
    return value.length === 0;
  }
  return value === '';
};

/**
 * @function validateEmail
 * @summary checks if email value is a valid email
 * @param {string} email
 * @returns {string} error - an empty string or error value
 */
export const validateEmail = (email: string): string => {
  const emailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
  );

  let error = '';

  if (!email || isEmpty(email)) {
    error = 'Email is required';
  } else {
    const isMatch = emailRegex.test(email.toLowerCase());
    error = isMatch ? '' : 'Email is invalid';
  }

  return error;
};

/**
 * @function validatePassword
 * @summary checks if password value is valid
 * @param {string} password
 * @returns {string} error - an empty string or error value
 */
export const validatePassword = (password: string): string => {
  let error = '';

  if (isEmpty(password)) {
    error = 'Password is required';
  }

  return error;
};

/**
 * @function validateName
 * @summary checks if name value is valid
 * @param {string} name
 * @param {string} lbale - name field label
 * @returns {string} error - an empty string or error value
 */
export const validateName = (name: string, label?: string): string => {
  let error = '';

  const nameRegex = new RegExp(/^([A-Za-z0-9_ ]+)$/);
  const prefix = label || 'Name';

  if (isEmpty(name)) {
    error = `${prefix} is required`;
  } else {
    const isMatch = nameRegex.test(name);
    error = isMatch ? '' : `${prefix} is invalid`;
  }

  return error;
};

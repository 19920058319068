import React from 'react';

interface IInputProps {
  label: string;
  placeholder: string;
  name: string;
  value?: string | number | readonly string[] | undefined;
  error?: string | null;
  onChange?: (event: React.ChangeEvent<HTMLTextAreaElement>) => void;
}

const defaultProps = {
  placeholder: '',
  label: '',
  onChange: null,
};

function Input(props: IInputProps) {
  const { label, placeholder, name, value, error, onChange } = props;

  return (
    <div className="relative w-full mb-8">
      <label
        className="block capitalize text-gray-800 text-sm mb-2"
        htmlFor={name}>
        {label}
      </label>
      <textarea
        className="appearance-none block w-full bg-gray-100 text-gray-700 text-sm border border-gray-100 rounded py-3 px-4 leading-tight focus:outline-none focus:bg-white focus:border-primary"
        id={name}
        name={name}
        placeholder={placeholder}
        value={value}
        onChange={onChange}
        style={{ transition: 'all .15s ease' }}></textarea>
      {error && <span className="mt-2 text-xs text-error">{error}</span>}
    </div>
  );
}

Input.defaultProps = defaultProps;
export default Input;

/**
 * @file Manages all global variables used in the app
 */

import { gql } from '@apollo/client';

export interface IAction {
  type: string;
  data?: any;
}

export interface IOrganizationModel {
  id: string;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
  createdDate: Date;
  updatedDate: Date;
  deletedDate: Date;
}

export interface IUserModel {
  id: string;
  email: string;
  role: string;
  createdDate: Date;
  updatedDate: Date;
  deletedDate: Date;
  profile: IProfileModel;
  organization: IOrganizationModel;
}

export interface IProfileModel {
  id: string;
  name: string;
  phoneNumber: string;
  active: boolean;
  isDefault: boolean;
  createdDate: Date;
  updatedDate: Date;
  deletedDate: Date;
  user: IUserModel;
  organization: IOrganizationModel;
}

export const FETCH_USER = gql`
  query Query {
    user {
      id
      email
      role
      profile {
        name
      }
    }
  }
`;

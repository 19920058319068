import * as types from './types';
import { getAuthToken, isTokenExpired, clearAuthToken } from '../../lib/auth';
import { IUserModel } from '../../constants/global';

export function logoutUser() {
  clearAuthToken();
  return { type: types.LOGOUT_USER };
}

export function setLoadingStatus(loading: boolean) {
  return { type: types.SET_LOADING_STATUS, data: { loading } };
}

export function setAuthStatus() {
  const token = getAuthToken();
  const isExpired = isTokenExpired(token);
  return {
    type: types.SET_AUTH_STATUS,
    data: { isAuthenticated: !!token, isActiveSession: !isExpired },
  };
}

export function setUser(data: IUserModel) {
  return {
    type: types.SET_USER,
    data: { user: data },
  };
}

export function setAuthError(error: string) {
  return {
    type: types.SET_AUTH_ERROR,
    data: { error },
  };
}

import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { gql, useQuery } from '@apollo/client';

import { useAuthState } from '../auth';
import { IAction } from '../../constants/global';
import { PERMISSIONS, ROLE_PERMISSIONS } from '../../constants/user';
import {
  setLoadingStatus,
  setOrganisations,
  setDashboardError,
} from './actions';
import reducer from './reducer';
import initialState, { IState } from './state';

type IDispatch = (action: IAction) => void;

export const FETCH_ORAGNISATIONS = gql`
  query Query {
    fetchOrganizations {
      totalCount
      list {
        id
        name
        address
        email
        phoneNumber
      }
    }
  }
`;

const DashboardDispatchContext = createContext<IDispatch | undefined>(
  undefined,
);
const DashboardStateContext = createContext<IState>(initialState);

export const DashboardProvider = ({ children }: { children: any }) => {
  const { isAuthenticated, user } = useAuthState();
  const [state, dispatch] = useReducer(reducer, initialState);

  const canFetchOrganisations = user
    ? ROLE_PERMISSIONS[user.role].includes(PERMISSIONS.VIEW_ORGANIZATIONS)
    : false;

  const { loading, data, error } = useQuery(FETCH_ORAGNISATIONS, {
    skip: !(isAuthenticated && canFetchOrganisations),
  });

  useEffect(() => {
    async function loadOrganisations() {
      if (!loading && data) {
        dispatch(setOrganisations(data.fetchOrganizations));
      }
      if (!loading && error) {
        dispatch(setDashboardError(error.message));
      }
      setLoadingStatus(loading);
    }
    loadOrganisations();
  }, [loading, data, error]);

  return (
    <DashboardDispatchContext.Provider value={dispatch}>
      <DashboardStateContext.Provider value={{ ...state }}>
        {children}
      </DashboardStateContext.Provider>
    </DashboardDispatchContext.Provider>
  );
};

export function useDashboardDispatch() {
  const context = useContext(DashboardDispatchContext);

  if (context === undefined) {
    throw new Error(
      'useDashboardDispatch must be used within a DashboardProvider',
    );
  }

  return context;
}

export function useDashboardState() {
  const context = useContext(DashboardStateContext);

  if (context === undefined) {
    throw new Error(
      'useDashboardState must be used within a DashboardProvider',
    );
  }

  return context;
}

import React from 'react';

import LogoSVG from '../../assets/svgs/wepray.svg';

import './logo.scss';

interface ILogoProps {
  size: 'default' | 'small' | 'large';
  logoClassName?: string;
}

const defualtProps = {
  size: 'default',
};

function Logo(props: ILogoProps) {
  const { logoClassName, size } = props;

  return (
    <span className={`logo__container logo--${size} ${logoClassName}`}>
      <img src={LogoSVG} alt="wepray" />
    </span>
  );
}

Logo.defaultProps = defualtProps;
export default Logo;

import { gql } from '@apollo/client';

export interface IUserProps {
  email: string;
  password: string;
}

export const LOGIN_MUTATION = gql`
  mutation Mutation($loginInput: LoginInput!) {
    login(loginInput: $loginInput) {
      accessToken
    }
  }
`;

import React from 'react';
import { X } from 'react-feather';

import Alert, { IAlertProps } from '../Alert';
import Button from '../Button';

interface IModalProps {
  visible: boolean;
  title: string;
  onClose: () => void;
  onOk: () => void;
  cancelText: string;
  okText: string;
  loading: boolean;
  children: React.ReactNode;
  alert?: IAlertProps;
}

const defaultProps = {
  cancelText: 'Close',
  okText: 'Ok',
  loading: true,
};

function Modal(props: IModalProps) {
  const {
    visible,
    onClose,
    onOk,
    title,
    children,
    cancelText,
    okText,
    loading,
    alert,
  } = props;

  if (!visible) return null;

  return (
    <>
      <div
        role="dialog"
        aria-labelledby="dialog"
        className="flex overflow-x-hidden justify-center mt-32 px-2 overflow-y-auto fixed inset-0 z-50 outline-none focus:outline-none">
        <div className="relative w-full max-w-screen-sm my-6">
          {/*content*/}
          <div className="flex flex-col w-full bg-white border-0 rounded-lg shadow-lg relative outline-none focus:outline-none">
            {/*header*/}
            <div className="flex items-center justify-between px-6 py-4 border-b border-solid border-gray-300 rounded-t">
              <h3 className="text-base">{title}</h3>
              <button
                className="p-1 bg-transparent border-0 text-black text-base opacity-5 leading-none font-light outline-none focus:outline-none"
                onClick={onClose}>
                <span className="flex items-center justify-center text-black h-6 w-6 text-xl outline-none focus:outline-none">
                  <X size="16" />
                </span>
              </button>
            </div>

            {alert && alert.message && (
              <Alert type={alert.type} message={alert.message} />
            )}

            {/*body*/}
            <div className="relative p-6 flex-auto">{children}</div>

            {/*footer*/}
            <div className="flex items-center justify-end px-6 py-3 border-t border-solid border-gray-300 rounded-b">
              <Button onClick={onClose}>{cancelText}</Button>
              <Button
                type="primary"
                onClick={onOk}
                className="ml-4"
                loading={loading}>
                {okText}
              </Button>
            </div>
          </div>
        </div>
      </div>
      <div
        className="opacity-25 fixed inset-0 z-40 bg-black"
        onClick={onClose}
      />
    </>
  );
}

Modal.defaultProps = defaultProps;
export default Modal;

import React from 'react';
import { Icon } from 'react-feather';

interface IStatCardProps {
  icon?: Icon;
  title: string;
  value: number;
}

function StatCard(props: IStatCardProps) {
  const { icon, title, value } = props;
  const IconNode = icon || null;

  return (
    <div className="relative w-full flex flex-col min-w-0 break-words bg-white rounded mb-6 shadow-sm">
      <div className="flex-auto flex flex-wrap p-4">
        <div className="relative w-full pr-4 max-w-full flex-grow flex-1">
          <h5 className="text-gray-500 capitalize font-semibold text-sm mb-2">
            {title}
          </h5>
          <span className="font-semibold text-xl text-primary-light">
            {value}
          </span>
        </div>
        {IconNode && (
          <div className="relative w-auto pl-4 flex-initial">
            <div className="text-white p-3 text-center inline-flex items-center justify-center w-12 h-12 shadow-sm rounded-full bg-primary-light">
              <IconNode />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default StatCard;

import { IOrganisations } from './types';

export interface IState {
  organisations: IOrganisations;
  error: string;
  loading: boolean;
}

const initialState = {
  organisations: {
    list: [],
    totalCount: 0,
  },
  error: '',
  loading: false,
};

export default initialState;

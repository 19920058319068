import * as types from './types';
import { IOrganizationModel } from './types';

export function setLoadingStatus(loading: boolean) {
  return { type: types.SET_LOADING_STATUS, data: { loading } };
}

export function setOrganisations(data: IOrganizationModel) {
  return {
    type: types.SET_ORGANISATIONS,
    data: { organisations: data },
  };
}

export function setDashboardError(error: string) {
  return {
    type: types.SET_DASHBOARD_ERROR,
    data: { error },
  };
}

import React from 'react';

declare const CellTypes: ['th', 'td'];

interface ITableCellProps {
  type: typeof CellTypes[number];
  children: React.ReactNode;
}

const defaultProps = {
  type: 'td',
};

function TableCell(props: ITableCellProps) {
  const { type, children } = props;
  const isHeaderCell = type === 'th';

  if (isHeaderCell) {
    return (
      <th className="px-6 py-6 bg-gray-100 text-gray-600 align-middle border border-solid border-gray-200 text-xs uppercase border-l-0 border-r-0 whitespace-no-wrap font-semibold text-left">
        {children}
      </th>
    );
  }
  return (
    <td className="border-t-0 px-6 p-4 align-middle border-l-0 border-r-0 text-xs whitespace-no-wrap">
      {children}
    </td>
  );
}

TableCell.defaultProps = defaultProps;
export default TableCell;

import initialState from './state';
import * as types from './types';
import { IAction } from '../../constants/global';

function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.SET_AUTH_STATUS:
      return {
        ...state,
        isActiveSession: action.data.isActiveSession,
        isAuthenticated: action.data.isAuthenticated,
      };
    case types.SET_USER:
      return {
        ...state,
        user: action.data.user,
        error: '',
      };
    case types.SET_AUTH_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        loading: action.data.loading,
      };
    case types.LOGOUT_USER:
      return {
        ...state,
        user: null,
        isActiveSession: false,
        isAuthenticated: false,
      };
    default:
      return state;
  }
}

export default reducer;

import React from 'react';

interface ITableBodyProps {
  children: React.ReactNode;
}
function TableBody(props: ITableBodyProps) {
  const { children } = props;

  return <tbody>{children}</tbody>;
}

export default TableBody;

import React from 'react';
import { Menu } from 'react-feather';

import { IUserModel } from '../../constants/global';
import { ROLES } from '../../constants/user';

import Button from '../../components/Button';
import Dropdown from '../Dropdown';
import Logo from '../Logo';
import HeaderLink, { HEADER_LINK_CLASS_NAME } from './HeaderLink';

interface IHeaderProps {
  logout: () => void;
  user: IUserModel | null;
}

function Header(props: IHeaderProps) {
  const { logout, user } = props;
  const [navbarOpen, setNavbarOpen] = React.useState(false);
  return (
    <header className="fixed top-0 left-0 right-0 z-10 flex flex-wrap items-center justify-between px-2 md:px-6 py-4 navbar-expand-lg bg-primary">
      <div className="w-full px-4 mx-auto flex flex-wrap items-center justify-between">
        <div className="w-full relative flex justify-between md:w-auto md:static md:block md:justify-start">
          <Logo size="small" />
          <button
            className="text-white cursor-pointer text-xl leading-none px-3 py-1 border border-solid border-transparent rounded bg-transparent block md:hidden outline-none focus:outline-none"
            type="button"
            onClick={() => setNavbarOpen(!navbarOpen)}>
            <Menu />
          </button>
        </div>
        <div
          className={
            'md:flex flex-grow items-center' +
            (navbarOpen ? ' flex' : ' hidden')
          }>
          <nav className="flex flex-col md:flex-row md:items-center mt-6 md:mt-0 md:ml-auto">
            {user?.id &&
            (user.role === ROLES.APP_ADMIN ||
              user.role === ROLES.SUPER_ADMIN) ? (
              <>
                <HeaderLink title="Overview" url="/dashboard" />
                <HeaderLink title="Users" url="/dashboard/users" />
                <HeaderLink
                  title="Organisations"
                  url="/dashboard/organisations"
                />
              </>
            ) : null}
            {user && user.id && (
              <Dropdown
                iconClassName="text-white"
                menu={
                  <>
                    <Button
                      type="text"
                      className="w-full justify-start"
                      onClick={logout}>
                      Logout
                    </Button>
                  </>
                }>
                <span className={HEADER_LINK_CLASS_NAME}>
                  {user.profile.name}
                </span>
              </Dropdown>
            )}
          </nav>
        </div>
      </div>
    </header>
  );
}

export default Header;

import { IUserModel } from '../../constants/global';

export interface IState {
  isAuthenticated: boolean;
  isActiveSession: boolean;
  user: IUserModel | null;
  error: string;
  loading: boolean;
  refetch?: () => void;
}

const initialState = {
  isAuthenticated: false,
  isActiveSession: false,
  user: null,
  error: '',
  loading: false,
};

export default initialState;

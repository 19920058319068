import React from 'react';

import Input from '../../../components/Input';
import Select from '../../../components/Select';

import { IAdminInput } from '../../../constants/inputs';
import { IOrganizationModel } from '../../../contexts/dashboard/types';

interface IAdminFormProps {
  admin: IAdminInput;
  fieldErrors: IAdminInput;
  onValueChange: React.FormEventHandler;
  loading: boolean;
  organisations: Array<IOrganizationModel>;
  showOrganisationField: boolean;
  showPasswordField: boolean;
}

function AdminForm(props: IAdminFormProps) {
  const {
    admin,
    fieldErrors,
    onValueChange,
    loading,
    organisations,
    showOrganisationField,
    showPasswordField,
  } = props;

  const options = organisations.map((organisation) => {
    return {
      key: organisation.id,
      value: organisation.name,
      label: organisation.name,
    };
  });

  return (
    <form className="px-2 md:px-6">
      <Input
        name="name"
        label="Admin Name"
        value={admin.name}
        error={fieldErrors.name}
        onChange={onValueChange}
      />
      <Input
        name="email"
        label="Email"
        type="email"
        value={admin.email}
        error={fieldErrors.email}
        onChange={onValueChange}
      />
      {showOrganisationField && (
        <Select
          loading={loading}
          name="organizationName"
          label="Organisation"
          selectOptions={options}
          onChange={onValueChange}
          error={fieldErrors.organizationName}
        />
      )}
      {showPasswordField && (
        <Input
          name="password"
          label="Password"
          type="password"
          value={admin.password}
          error={fieldErrors.password}
          onChange={onValueChange}
        />
      )}
    </form>
  );
}

export default AdminForm;

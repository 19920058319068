import React from 'react';

interface ITableRowProps {
  children: React.ReactNode;
}

function TableRow(props: ITableRowProps) {
  const { children } = props;

  return <tr>{children}</tr>;
}

export default TableRow;

import React, { useEffect, useState } from 'react';
import { Layers, Plus, Users } from 'react-feather';

import { useAuthState } from '../../contexts/auth';
import { useDashboardState } from '../../contexts/dashboard';
import { PERMISSIONS, ROLE_PERMISSIONS } from '../../constants/user';

import Button from '../../components/Button';
import StatCard from '../../components/StatCard';
import AddAdminContainer from './containers/AddAdminContainer';
import AddOrganisationContainer from './containers/AddOrganisationContainer';

function Overview() {
  const { user } = useAuthState();
  const { organisations } = useDashboardState();
  const [permissions, setPermissions] = useState({
    canCreateAppAdmin: false,
    canCreateOrganisationAdmin: false,
    canCreateOrganisation: false,
  });
  const [showAddAdminModal, setShowAddAdminModal] = useState(false);
  const [showOrganisationModal, setShowOrganisationModal] = useState(false);

  const closeAddAdminModal = () => {
    setShowAddAdminModal(false);
  };
  const openAddAdminModal = () => {
    setShowAddAdminModal(true);
  };

  const closeOrganisationModal = () => {
    setShowOrganisationModal(false);
  };
  const openAddOrganisationModal = () => {
    setShowOrganisationModal(true);
  };

  const renderSubHeader = () => {
    if (user && user.id) {
      return (
        <span className="flex md:border-r-1 md:border-gray-200">
          {permissions.canCreateOrganisationAdmin && (
            <Button
              type="text"
              className="mr-4"
              icon={Plus}
              onClick={openAddAdminModal}>
              <span>Add Organisation Admin</span>
            </Button>
          )}
          {permissions.canCreateOrganisation && (
            <Button type="text" onClick={openAddOrganisationModal} icon={Plus}>
              <span>Add Organisation</span>
            </Button>
          )}
          {permissions.canCreateAppAdmin && (
            <Button
              type="text"
              className="mr-4"
              icon={Plus}
              onClick={openAddAdminModal}>
              <span>Add Admin</span>
            </Button>
          )}
        </span>
      );
    }
    return null;
  };

  useEffect(() => {
    if (user && user.id) {
      setPermissions({
        canCreateAppAdmin: ROLE_PERMISSIONS[user.role].includes(
          PERMISSIONS.CREATE_APP_ADMIN,
        ),
        canCreateOrganisationAdmin: ROLE_PERMISSIONS[user.role].includes(
          PERMISSIONS.CREATE_ORGANIZATION_ADMIN,
        ),
        canCreateOrganisation: ROLE_PERMISSIONS[user.role].includes(
          PERMISSIONS.CREATE_ORGANIZATION,
        ),
      });
    }
  }, [user]);

  return (
    <>
      <div className="bg-white px-6 py-4">{renderSubHeader()}</div>
      <div className="container flex flex-wrap md:flex-no-wrap relative h-full px-6 pt-8 pb-4">
        <div className="w-full md:w-1/4 px-4 rounded">
          <span className="block mb-4">
            <h2 className="text-black text-xl">Overview</h2>
          </span>
          <StatCard title="Users" value={0} icon={Users} />
          <StatCard
            title="Organisations"
            value={organisations.totalCount}
            icon={Layers}
          />
        </div>
        <div className="w-full md:w-3/4 px-2 rounded">
          <div className="">
            <h2 className="text-black text-xl">Metrics</h2>
          </div>
        </div>
      </div>
      {permissions.canCreateOrganisation && (
        <AddOrganisationContainer
          visible={showOrganisationModal}
          userRole={user?.role}
          handleClose={closeOrganisationModal}
        />
      )}
      {permissions.canCreateAppAdmin ||
        (permissions.canCreateOrganisationAdmin && (
          <AddAdminContainer
            visible={showAddAdminModal}
            userRole={user?.role}
            handleClose={closeAddAdminModal}
          />
        ))}
    </>
  );
}

export default Overview;

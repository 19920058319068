import React, { useRef, useState } from 'react';
import { Placement } from '@popperjs/core';
import { usePopper } from 'react-popper';
import { ChevronDown, ChevronUp } from 'react-feather';

interface IDropdownProps {
  menu: React.ReactElement;
  children: React.ReactElement;
  visible: boolean;
  placement: Placement;
  iconClassName?: string;
}

const defaultProps = {
  placement: 'bottom-start',
  visible: false,
};

function Dropdown(props: IDropdownProps) {
  const { children, visible, menu, iconClassName, placement } = props;
  const [showPopper, setShowPopper] = useState(visible);
  const buttonRef = useRef(null);
  const popperRef = useRef(null);

  // the ref for the arrow must be a callback ref
  const [arrowRef, setArrowRef] = useState<HTMLDivElement | null>(null);

  const { styles, attributes } = usePopper(
    buttonRef.current,
    popperRef.current,
    {
      placement,
      modifiers: [
        {
          name: 'arrow',
          options: {
            element: arrowRef,
            padding: {
              top: 0,
              left: 0,
              right: 0,
              bottom: 0,
            },
          },
        },
        {
          name: 'offset',
          options: {
            offset: [0, 8],
          },
        },
      ],
    },
  );

  return (
    <>
      <span className="flex items-center">
        {children}
        <span
          className={`ml-2 text-current ${iconClassName || ''}`}
          role="button"
          ref={buttonRef}
          onClick={() => setShowPopper(!showPopper)}>
          {showPopper ? <ChevronUp size="16" /> : <ChevronDown size="16" />}
        </span>
      </span>
      {showPopper ? (
        <div
          ref={popperRef}
          style={{ ...styles.popper, minWidth: '8rem' }}
          className="bg-white z-50 text-base py-2 list-none rounded shadow-lg"
          {...attributes.popper}>
          <div ref={setArrowRef} style={styles.arrow} id="arrow" />
          {menu}
        </div>
      ) : null}
    </>
  );
}

Dropdown.defaultProps = defaultProps;
export default Dropdown;

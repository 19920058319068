import React, { createContext, useContext, useEffect, useReducer } from 'react';
import { useQuery } from '@apollo/client';

import { IAction, FETCH_USER } from '../../constants/global';
import {
  setLoadingStatus,
  setAuthError,
  setAuthStatus,
  setUser,
} from './actions';
import reducer from './reducer';
import initialState, { IState } from './state';

type IDispatch = (action: IAction) => void;

const AuthDispatchContext = createContext<IDispatch | undefined>(undefined);
const AuthStateContext = createContext<IState>(initialState);

export const AuthProvider = ({ children }: { children: any }) => {
  const [state, dispatch] = useReducer(reducer, initialState);
  const { loading, error, data, refetch } = useQuery(FETCH_USER);

  useEffect(() => {
    async function loadUser() {
      if (!loading && data && data.user) {
        dispatch(setUser(data.user));
        dispatch(setAuthStatus());
      }
      if (!loading && error) {
        dispatch(setAuthError(error.message));
      }
      dispatch(setLoadingStatus(loading));
    }
    loadUser();
  }, [dispatch, loading, data, error]);

  return (
    <AuthDispatchContext.Provider value={dispatch}>
      <AuthStateContext.Provider value={{ ...state, refetch }}>
        {children}
      </AuthStateContext.Provider>
    </AuthDispatchContext.Provider>
  );
};

export function useAuthDispatch() {
  const context = useContext(AuthDispatchContext);

  if (context === undefined) {
    throw new Error('useAuthDispatch must be used within a AuthProvider');
  }

  return context;
}

export function useAuthState() {
  const context = useContext(AuthStateContext);

  if (context === undefined) {
    throw new Error('useAuthState must be used within a AuthProvider');
  }

  return context;
}

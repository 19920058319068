import React from 'react';
import {
  RouteComponentProps,
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  RouteProps,
} from 'react-router-dom';
import { CSSTransition, TransitionGroup } from 'react-transition-group';

import Landing from './views/Landing';
import Login from './views/Auth/Login';
import DashboardHome from './views/Dashboard';

import { AuthProvider } from './contexts/auth';
import { getAuthToken } from './lib/auth';

interface IAuthenticatedRouteProps extends RouteProps {
  component:
    | React.ComponentType<RouteComponentProps<any>>
    | React.ComponentType<any>;
}

const RequireAuth = ({ component, ...rest }: IAuthenticatedRouteProps) => {
  const RouteComponent = component;
  const authToken = getAuthToken();

  return (
    <Route
      {...rest}
      render={({ location, ...otherProps }) =>
        authToken ? (
          <RouteComponent location={location} {...otherProps} />
        ) : (
          <Redirect
            to={{
              pathname: '/login',
              state: { from: location },
            }}
          />
        )
      }
    />
  );
};

function App() {
  return (
    <BrowserRouter>
      <AuthProvider>
        <TransitionGroup>
          <CSSTransition classNames="fade" timeout={300}>
            <Switch>
              <Route exact path="/" component={Landing} />
              <Route path="/login" component={Login} />
              <RequireAuth path="/dashboard" component={DashboardHome} />
            </Switch>
          </CSSTransition>
        </TransitionGroup>
      </AuthProvider>
    </BrowserRouter>
  );
}

export default App;

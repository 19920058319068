import React from 'react';
import { Users as UsersIcon } from 'react-feather';

function Users() {
  return (
    <>
      <div className="container relative h-full px-6 py-4">
        <div className="flex items-center bg-white px-6 py-4">
          <span className="mr-4 text-gray-500">
            <UsersIcon size="16" />
          </span>
          <h2 className="text-black text-xl">Users</h2>
        </div>
      </div>
    </>
  );
}

export default Users;

/**
 * @file Manages all user variables, roles and permissions used inthe app
 */

export const APP_TOKEN = 'wpr-token';

export const PERMISSIONS = {
  CREATE_APP_ADMIN: 'CREATE_APP_ADMIN',
  CREATE_ORGANIZATION_ADMIN: 'CREATE_ORGANIZATION_ADMIN',
  CREATE_ORGANIZATION: 'CREATE_ORGANIZATION',
  VIEW_ORGANIZATIONS: 'VIEW_ORGANIZATIONS',
  VIEW_USERS: 'VIEW_USERS',
};

export const ROLES = {
  APP_ADMIN: 'APP_ADMIN',
  ORGANIZATION_ADMIN: 'ORGANIZATION_ADMIN',
  SUPER_ADMIN: 'SUPER_ADMIN',
  USER: 'USER',
};

export const ROLE_PERMISSIONS = {
  [ROLES.APP_ADMIN]: [
    PERMISSIONS.CREATE_ORGANIZATION,
    PERMISSIONS.CREATE_ORGANIZATION_ADMIN,
    PERMISSIONS.VIEW_ORGANIZATIONS,
  ],
  [ROLES.ORGANIZATION_ADMIN]: [],
  [ROLES.SUPER_ADMIN]: [PERMISSIONS.CREATE_APP_ADMIN],
  [ROLES.USER]: [],
};

import React from 'react';

interface ITableProps {
  children: React.ReactNode;
}

function Table(props: ITableProps) {
  const { children } = props;

  return (
    <table className="items-center w-full bg-transparent border-collapse">
      {children}
    </table>
  );
}

export default Table;

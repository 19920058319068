import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import { useDashboardState } from '../../../contexts/dashboard';
import Modal from '../../../components/Modal';
import AdminForm from '../forms/AdminForm';

import { PERMISSIONS, ROLES, ROLE_PERMISSIONS } from '../../../constants/user';
import { validateAppAdminInput } from '../../../lib/validators/admin';

import {
  IAppAdminInput,
  IOrganisationAdminInput,
} from '../../../constants/inputs';
import {
  IDashboardAlert,
  CREATE_APP_ADMIN,
  CREATE_ORGANISATION_ADMIN,
} from '../dashboard';

interface IAddAdminContainerProps {
  visible: boolean;
  userRole: string | undefined;
  handleClose: () => void;
}

const ROLE_TEXTS = {
  [ROLES.APP_ADMIN]: {
    modalTitle: 'Add Organisation Admin',
    okText: 'Create Organisation Admin',
  },
  [ROLES.SUPER_ADMIN]: {
    modalTitle: 'Add App Admin',
    okText: 'Create App Admin',
  },
};

const INITIAL_VALUES = { name: '', email: '' };

function AddAdminContainer(props: IAddAdminContainerProps) {
  const { visible, userRole, handleClose } = props;
  const dashboardState = useDashboardState();
  const [creatingAppAdmin, setCreatingAppAdmin] = useState(false);

  const ADMIN_INITIAL_VALUES =
    userRole === ROLES.APP_ADMIN
      ? {
          ...INITIAL_VALUES,
          organizationName: '',
        }
      : {
          ...INITIAL_VALUES,
          password: '',
        };
  const [alert, setAlert] = useState<IDashboardAlert>({ message: '' });
  const [fieldErrors, setFieldErrors] = useState<
    IAppAdminInput | IOrganisationAdminInput
  >(ADMIN_INITIAL_VALUES);
  const [admin, setAdmin] = useState<IAppAdminInput | IOrganisationAdminInput>(
    ADMIN_INITIAL_VALUES,
  );

  const [createAppAdmin] = useMutation(CREATE_APP_ADMIN);
  const [createOrganisationAdmin] = useMutation(CREATE_ORGANISATION_ADMIN);

  const closeAddAdminModal = () => {
    setFieldErrors(ADMIN_INITIAL_VALUES);
    setAlert({ message: '' });
    handleClose();
  };

  const createAdmin = async () => {
    const canCreateAppAdmin = userRole
      ? ROLE_PERMISSIONS[userRole].includes(PERMISSIONS.CREATE_APP_ADMIN)
      : false;
    const canCreateOrganisationAdmin = userRole
      ? ROLE_PERMISSIONS[userRole].includes(
          PERMISSIONS.CREATE_ORGANIZATION_ADMIN,
        )
      : false;

    try {
      if (canCreateOrganisationAdmin) {
        await createOrganisationAdmin({
          variables: { createOrganizationAdminInput: admin },
        });
      }
      if (canCreateAppAdmin) {
        await createAppAdmin({
          variables: { createAppAdminInput: admin },
        });
      }
      setAlert({
        type: 'success',
        message: 'Admin was successfully created',
      });
    } catch (error) {
      setAlert({
        type: 'error',
        message: error.message,
      });
    }
  };

  const handleAdminFormSubmit = async () => {
    setCreatingAppAdmin(true);
    const { isValid, errors } = validateAppAdminInput(admin);

    if (isValid) {
      await createAdmin();
    } else {
      setFieldErrors({ ...fieldErrors, ...errors });
    }
    setCreatingAppAdmin(false);
  };

  const handleAdminFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldErrors(ADMIN_INITIAL_VALUES);
    const { name, value } = event.target;

    setAdmin({
      ...admin,
      [name]: value,
    });
  };

  return (
    <Modal
      visible={visible}
      title={userRole ? ROLE_TEXTS[userRole].modalTitle : ''}
      onClose={closeAddAdminModal}
      onOk={handleAdminFormSubmit}
      loading={creatingAppAdmin}
      alert={alert}
      okText={userRole ? ROLE_TEXTS[userRole].okText : 'Ok'}>
      <AdminForm
        admin={admin}
        onValueChange={handleAdminFormChange}
        fieldErrors={fieldErrors}
        loading={dashboardState.loading}
        organisations={dashboardState.organisations.list}
        showOrganisationField={userRole === ROLES.APP_ADMIN}
        showPasswordField={userRole === ROLES.SUPER_ADMIN}
      />
    </Modal>
  );
}

export default AddAdminContainer;

export const SET_DASHBOARD_ERROR = 'wpr/dashboard/SET_DASHBOARD_ERROR';
export const SET_LOADING_STATUS = 'wpr/dashboard/SET_LOADING_STATUS';
export const SET_ORGANISATIONS = 'wpr/dashboard/SET_ORGANISATIONS';

export interface IOrganizationModel {
  id: string;
  name: string;
  address: string;
  email: string;
  phoneNumber: string;
  createdDate: Date;
  updatedDate: Date;
  deletedDate: Date;
}

export interface IOrganisations {
  list: Array<IOrganizationModel>;
  totalCount: number;
}

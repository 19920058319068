import initialState from './state';
import * as types from './types';
import { IAction } from '../../constants/global';

function reducer(state = initialState, action: IAction) {
  switch (action.type) {
    case types.SET_LOADING_STATUS:
      return {
        ...state,
        loading: action.data.loading,
      };
    case types.SET_ORGANISATIONS:
      return {
        ...state,
        organisations: action.data.organisations,
      };
    case types.SET_DASHBOARD_ERROR:
      return {
        ...state,
        error: action.data.error,
      };
    default:
      return state;
  }
}

export default reducer;

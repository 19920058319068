import React, { useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useMutation } from '@apollo/client';

import Alert from '../../components/Alert';
import Button from '../../components/Button';
import Checkbox from '../../components/Checkbox';
import Container from '../../components/Container';
import Input from '../../components/Input';

import { useAuthState } from '../../contexts/auth';

import { validateLoginDetails } from '../../lib/validators/auth';
import { saveAuthToken } from '../../lib/auth';

import { IUserProps, LOGIN_MUTATION } from './auth';

const USER_INITIAL_VALUES = {
  email: '',
  password: '',
};

function Login() {
  const history = useHistory();
  const authState = useAuthState();
  const [redirectRoute, setRedirectRoute] = useState(false);
  const [user, setUser] = useState(USER_INITIAL_VALUES);
  const [fieldErrors, setFieldErrors] = useState<IUserProps>(
    USER_INITIAL_VALUES,
  );
  const [authError, setAuthError] = useState('');
  const [loading, setLoading] = useState(false);

  const [login] = useMutation(LOGIN_MUTATION);

  const clearErrors = () => {
    if (fieldErrors.email || fieldErrors.password) {
      setFieldErrors(USER_INITIAL_VALUES);
    }
    if (authError) {
      setAuthError('');
    }
  };

  const handleInputChange = async (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    clearErrors();

    const { name, value } = event.target;
    setUser({
      ...user,
      [name]: value,
    });
  };

  const handleUserLogin = async () => {
    try {
      const { data } = await login({ variables: { loginInput: user } });
      saveAuthToken(data.login.accessToken);
      setRedirectRoute(true);
    } catch (error) {
      setAuthError(error.message);
    }
  };

  const handleFormSubmit = async (event: React.FormEvent) => {
    event.preventDefault();
    setLoading(true);

    const { isValid, errors } = validateLoginDetails(user);

    if (isValid) {
      await handleUserLogin();
    } else {
      setFieldErrors({
        ...fieldErrors,
        ...errors,
      });
    }
    setLoading(false);
  };

  useEffect(() => {
    async function handleRedirect() {
      if (authState.refetch) {
        authState.refetch();
      }
      history.push('/dashboard');
    }
    if (redirectRoute) {
      handleRedirect();
    } else {
      setRedirectRoute(false);
    }
  }, [redirectRoute, history, authState.refetch, authState]);

  return (
    <Container>
      <div className="w-full md:w-2/3 lg:w-4/6 px-4 lg:px-16 xl:px-32 mx-auto">
        <div className="w-full lg:pt-12 pt-6 px-4">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 pb-12 shadow-lg rounded">
            <div className="px-4 py-6 flex-auto text-center">
              <h6 className="text-black text-2xl">Log In</h6>
            </div>
            <hr className="border-b-1 border-gray-200" />
            {authError && <Alert type="error" message={authError} />}
            <div className="flex flex-col p-8 lg:px-12 flex-auto">
              <form className="px-2">
                <Input
                  name="email"
                  label="Email"
                  value={user.email}
                  error={fieldErrors.email}
                  onChange={handleInputChange}
                />
                <Input
                  name="password"
                  label="Password"
                  type="password"
                  value={user.password}
                  error={fieldErrors.password}
                  onChange={handleInputChange}
                />
                <Checkbox label="Remember me" name="rememberMe" />
                <div className="text-center mt-6">
                  <Button
                    type="primary"
                    className="w-full"
                    onClick={handleFormSubmit}
                    loading={loading}>
                    {loading ? 'Authenticating...' : 'Sign In'}
                  </Button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Login;

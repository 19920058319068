import React, { useState } from 'react';
import { X } from 'react-feather';

import './alert.scss';

export declare const AlertTypes: ['info', 'success', 'warning', 'error'];

export interface IAlertProps {
  type?: typeof AlertTypes[number];
  message: string;
  onClose?: () => void;
}

const defaultProps = {
  type: 'info',
  message: '',
};

function Alert(props: IAlertProps) {
  const { type, message, onClose } = props;
  const [showAlert, setShowAlert] = useState(true);

  const closeAlert = () => {
    if (onClose) {
      onClose();
    }
    setShowAlert(false);
  };

  return (
    <>
      {showAlert && type ? (
        <div
          className={`alert alert--${type} text-base px-6 py-4 border-0 rounded relative mb-2`}>
          <span className="inline-block align-middle mr-8">{message}</span>
          <button
            className="align-middle p-1 bg-transparent text-base font-light opacity-5 leading-none outline-none focus:outline-none"
            onClick={closeAlert}>
            <span className="flex items-center justify-center text-black h-6 w-6 text-xl outline-none focus:outline-none">
              <X size="16" />
            </span>
          </button>
        </div>
      ) : null}
    </>
  );
}

Alert.defaultProps = defaultProps;
export default Alert;

import React from 'react';

interface IContainerProps {
  children: React.ReactNode;
  showBackground: boolean;
}

const defaulProps = {
  showBackground: true,
};

function Container(props: IContainerProps) {
  const { showBackground, children } = props;

  return (
    <main className="relative pt-16 pb-32 flex content-center items-center justify-center min-h-screen">
      {showBackground && (
        <div className="absolute top-0 w-full h-full bg-center bg-cover bg-brand--main" />
      )}
      <div className="container relative mx-auto h-full">{children}</div>
    </main>
  );
}

Container.defaultProps = defaulProps;
export default Container;

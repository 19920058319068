import React from 'react';
import { RouteComponentProps, useHistory } from 'react-router-dom';
import { ArrowRight } from 'react-feather';

import { useAuthState } from '../../contexts/auth';

import { clearAuthToken } from '../../lib/auth';

import Button from '../../components/Button';
import Container from '../../components/Container';
import Logo from '../../components/Logo';
import Spin from '../../components/Spin';

interface IPageProps {
  route: RouteComponentProps;
}

function Landing() {
  const history = useHistory();
  const { loading, user } = useAuthState();

  const goToDashboard = () => {
    history.push('/dashboard');
  };

  const goToLogin = () => {
    clearAuthToken();
    history.push('/login');
  };

  return (
    <Container>
      <div className="w-full md:w-2/4 lg:w-5/12 px-4 mx-auto text-center">
        <Logo size="large" logoClassName="mx-auto" />
        <div className="w-full lg:pt-12 pt-6 px-4 text-center">
          <div className="relative flex flex-col min-w-0 break-words bg-white w-full mb-8 py-12 shadow-lg rounded">
            <Spin loading={loading}>
              <div className="px-4 flex-auto">
                <h6 className="text-xl font-semibold">
                  {user && user.id ? "You're Signed In" : 'Not Signed In'}
                </h6>
              </div>
              <div className="flex flex-col items-center justify-center p-8 lg:px-12 flex-auto">
                {user && user.id && (
                  <div className="pt-4 w-full">
                    <Button
                      className="w-full"
                      type="primary"
                      onClick={goToDashboard}>
                      <span className="mr-2">{`Continue as ${user.profile.name}`}</span>
                      <ArrowRight size="16" />
                    </Button>
                    <span className="flex items-center py-6">
                      <hr className="flex-1 text-gray-400" />
                      <span className="mx-4 text-sm text-gray-500">OR</span>
                      <hr className="flex-1 text-gray-400" />
                    </span>
                  </div>
                )}
                <Button className="w-full" type="primary" onClick={goToLogin}>
                  <span className="mr-2">Start New Sign In</span>
                  <ArrowRight size="16" />
                </Button>
              </div>
            </Spin>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default Landing;

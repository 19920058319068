import React from 'react';
import { NavLink } from 'react-router-dom';

interface IHeaderLinkProps {
  title: string;
  url: string;
}

export const HEADER_LINK_CLASS_NAME =
  'md:px-3 py-2 flex items-center font-light leading-snug text-white hover:opacity-75';

function HeaderLink(props: IHeaderLinkProps) {
  const { title, url } = props;
  return (
    <NavLink
      exact
      to={url}
      className={HEADER_LINK_CLASS_NAME}
      activeClassName="font-medium">
      <span>{title}</span>
    </NavLink>
  );
}

export default HeaderLink;

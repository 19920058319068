import { validateEmail, validatePassword } from './utils';

type userLogin = {
  email: string;
  password: string;
};

/**
 * @function validateLoginDetails
 * @summary validates user login values
 * @param {object} user - string value or array
 * @returns isValid boolean value and error object
 */
export const validateLoginDetails = (user: userLogin) => {
  const { email, password } = user;

  const errors = {
    email: validateEmail(email),
    password: validatePassword(password),
  };

  const errorValues = Object.values(errors).filter((item) => item);

  return {
    isValid: errorValues.length === 0,
    errors,
  };
};

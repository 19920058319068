import React, { useState } from 'react';
import { useMutation } from '@apollo/client';

import Modal from '../../../components/Modal';
import OrganisationForm from '../forms/OrganisationForm';

import { validateOrganisationInput } from '../../../lib/validators/admin';

import { IOrganisationInput } from '../../../constants/inputs';
import { IDashboardAlert, CREATE_ORGANISATION } from '../dashboard';

interface IAddOrganisationContainerProps {
  visible: boolean;
  userRole: string | undefined;
  handleClose: () => void;
}

const ORGANISATION_INITIAL_VALUES = {
  name: '',
  address: '',
  email: '',
  phoneNumber: '',
};

function AddOrganisationContainer(props: IAddOrganisationContainerProps) {
  const { visible, handleClose } = props;
  const [organisation, setOrganisation] = useState<IOrganisationInput>(
    ORGANISATION_INITIAL_VALUES,
  );
  const [alert, setAlert] = useState<IDashboardAlert>({ message: '' });
  const [fieldErrors, setFieldErrors] = useState(ORGANISATION_INITIAL_VALUES);

  const [createOrganisation] = useMutation(CREATE_ORGANISATION);
  const [creatingOrganisation, setCreatingOrganisation] = useState(false);

  const closeOrganisationModal = () => {
    setFieldErrors(ORGANISATION_INITIAL_VALUES);
    setAlert({ message: '' });
    handleClose();
  };

  const handleOrganisationFormSubmit = async () => {
    setCreatingOrganisation(true);
    const { isValid, errors } = validateOrganisationInput(organisation);
    if (isValid) {
      try {
        await createOrganisation({
          variables: { createOrganizationInput: organisation },
        });
        setAlert({
          type: 'success',
          message: 'Admin was successfully created',
        });
      } catch (error) {
        setAlert({
          type: 'error',
          message: error.message,
        });
      }
    } else {
      setFieldErrors({ ...fieldErrors, ...errors });
    }
    setCreatingOrganisation(false);
  };

  const handleOrganisationFormChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setFieldErrors(ORGANISATION_INITIAL_VALUES);
    const { name, value } = event.target;
    setOrganisation({
      ...organisation,
      [name]: value,
    });
  };

  return (
    <Modal
      visible={visible}
      title="Add Organisation"
      onClose={closeOrganisationModal}
      onOk={handleOrganisationFormSubmit}
      loading={creatingOrganisation}
      okText="Create Organisation"
      alert={alert}>
      <OrganisationForm
        organisation={organisation}
        onValueChange={handleOrganisationFormChange}
        fieldErrors={fieldErrors}
      />
    </Modal>
  );
}

export default AddOrganisationContainer;

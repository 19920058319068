import React from 'react';
import { Loader, Icon } from 'react-feather';

import './button.scss';

declare const ButtonHTMLTypes: ['submit', 'button', 'reset'];
declare const ButtonTypes: ['default', 'primary', 'text'];

interface IButtonProps {
  children: React.ReactNode;
  htmlType: typeof ButtonHTMLTypes[number];
  className: string;
  loading: boolean;
  type: typeof ButtonTypes[number];
  onClick: React.FormEventHandler<HTMLButtonElement>;
  icon?: Icon;
}

const defaultProps = {
  children: 'Button',
  htmlType: 'button',
  loading: false,
  type: 'default',
  onClick: null,
  className: '',
};

function Button(props: IButtonProps) {
  const { children, className, htmlType, loading, type, icon, onClick } = props;

  const showIcon = icon || loading;
  const IconNode = icon ? icon : null;

  return (
    <button
      className={`btn btn--${type} px-4 py-3 rounded focus:outline-none ${className}`}
      type={htmlType}
      disabled={loading}
      onClick={onClick}
      style={{ transition: 'all .15s ease' }}>
      {showIcon && (
        <span className={`btn--${type}-icon mr-2 ${loading ? 'spin' : ''}`}>
          {loading && <Loader size="16" />}
          {!loading && IconNode && <IconNode size="16" />}
        </span>
      )}
      {children}
    </button>
  );
}

Button.defaultProps = defaultProps;
export default Button;

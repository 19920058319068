import jwtDecode from 'jwt-decode';

import { APP_TOKEN } from '../constants/user';

/**
 * @function saveAuthToken
 * @summary saves auth token to localstorage
 * @param {string} token - auth token
 */
export function saveAuthToken(token: string) {
  localStorage.setItem(APP_TOKEN, token);
}

/**
 * @function clearAuthToken
 * @summary clear auth token from local storage
 */
export function clearAuthToken() {
  localStorage.removeItem(APP_TOKEN);
}

/**
 * @function getAuthToken
 * @summary geta auth token from local storage
 * @returns token - auth token
 */
export function getAuthToken() {
  const token = localStorage.getItem(APP_TOKEN);
  return token;
}

/**
 * @function decodeToken
 * @summary decodes a token
 * @param {string} token - auth token
 * @returns decodedToken
 */
export function decodeToken(token: string | null): any {
  if (token) {
    const decodedToken = jwtDecode(token);
    return decodedToken;
  }
  return null;
}

/**
 * @function isTokenExpired
 * @summary checks if auth token is expired
 * @param {string} token - auth token
 * @returns boolean value
 */
export function isTokenExpired(token: string | null) {
  if (token) {
    const decodedToken = decodeToken(token);
    const expiresAt = new Date(decodedToken.exp * 1000);

    return new Date() > expiresAt;
  }
  return !token;
}

import React from 'react';

import Input from '../../../components/Input';
import TextArea from '../../../components/TextArea';

import { IOrganisationInput } from '../../../constants/inputs';

interface IOrganisationFormProps {
  organisation: IOrganisationInput;
  onValueChange: React.FormEventHandler;
  fieldErrors: IOrganisationInput;
}

function OrganisationForm(props: IOrganisationFormProps) {
  const { organisation, fieldErrors, onValueChange } = props;

  return (
    <form className="px-2 md:px-6">
      <Input
        name="name"
        label="Name"
        value={organisation.name}
        error={fieldErrors.name}
        onChange={onValueChange}
      />
      <Input
        name="email"
        label="Email"
        type="email"
        value={organisation.email}
        error={fieldErrors.email}
        onChange={onValueChange}
      />
      <Input
        name="phoneNumber"
        label="Phone Number"
        value={organisation.phoneNumber}
        error={fieldErrors.phoneNumber}
        onChange={onValueChange}
      />
      <TextArea
        name="address"
        label="Address"
        value={organisation.address}
        error={fieldErrors.address}
        onChange={onValueChange}
      />
    </form>
  );
}

export default OrganisationForm;

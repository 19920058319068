import { IAdminInput, IOrganisationInput } from '../../constants/inputs';
import {
  isEmpty,
  validateEmail,
  validateName,
  validatePassword,
} from './utils';

/**
 * @function validateAppAdminInput
 * @summary validates appAdmin input values
 * @param {object} appAdmin
 * @returns isValid boolean value and error object
 */
export function validateAppAdminInput(appAdmin: IAdminInput) {
  const { name, email, password, organizationName } = appAdmin;

  const errors: IAdminInput = {
    name: validateName(name),
    email: validateEmail(email),
  };

  if (password !== undefined) {
    errors.password = validatePassword(password);
  }

  if (organizationName !== undefined && isEmpty(organizationName)) {
    errors.organizationName = 'Organisation name is required';
  }

  const errorValues = Object.values(errors).filter((item) => item);

  return {
    isValid: errorValues.length === 0,
    errors,
  };
}

/**
 * @function validateOrganisationInput
 * @summary validates organisation input values
 * @param {object} organisation
 * @returns isValid boolean value and error object
 */
export function validateOrganisationInput(organisation: IOrganisationInput) {
  const { name, email } = organisation;

  const errors = {
    name: validateName(name),
    email: validateEmail(email),
  };

  const errorValues = Object.values(errors).filter((item) => item);

  return {
    isValid: errorValues.length === 0,
    errors,
  };
}
